import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { checkAuth, getLoggedInUser } from './services/auth.service';



// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const DeliveriesList = React.lazy(() => import('./pages/DeliveriesList'));
const DeliveriesNew = React.lazy(() => import('./pages/DeliveriesNew'));
const DeliveryDetail = React.lazy(() => import('./pages/DeliveryDetail'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {

    const logedInUser = checkAuth();
    if (!logedInUser) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/register', name: 'Register', component: Register, route: Route },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },

  // other pages
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['Admin'], title: 'Dashboard' },
  { path: '/deliveries/list', name: 'DeliveriesList', component: DeliveriesList, route: PrivateRoute, roles: ['Admin'], title: 'Deliveries List' },
  { path: '/deliveries/new', name: 'DeliveriesNew', component: DeliveriesNew, route: PrivateRoute, roles: ['Admin'], title: 'Deliveries New' },
  { path: '/deliveries/details/:guid', name: 'DeliveryDetail', component: DeliveryDetail, route: PrivateRoute, roles: ['Admin'], title: 'DeliveryDetail' },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/deliveries/list" />,
    route: PrivateRoute
  },
  // {
  //   path: "**",
  //   exact: true,
  //   component: () => <Redirect to="/deliveries/list" />,
  //   route: PrivateRoute
  // },
  
]

export { routes, PrivateRoute };