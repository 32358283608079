import Cookies from 'universal-cookie';

import axios from 'axios';

//const base_url = 'https://pre-vidsignercloud.validatedid.com/api/v2.1'
const base_url = 'https://vidsignercloud.validatedid.com/api/v2.1'


const login = async (username, password) => {
  var encodedString = btoa(`${username}:${password}`);
  return new Promise((resolve, reject) => {
    axios.get(
      `${base_url}/edeliveries`,
      { headers: { 'Authorization': `Basic ${encodedString}` } }
    ).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem('token', encodedString);
        sessionStorage.setItem('user', username);
        return resolve(true);
      }
    }).catch((err) => {
      reject(err.response)
    })
  })
}

const logout = () => {
  const cookies = new Cookies();
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  window.location.reload();
}

const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //     console.warn('access token expired');
  //     return false;
  // }
  // else {
  //     return true;
  // }
  return true;
}


const checkAuthWithApiCall = async () => {
  //const cookies = new Cookies();
  //const user = cookies.get("user");
  //return user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;

  let response;
  response =
    await axios.get(
      `${base_url}/edeliveries`,
      { headers: { 'Authorization': `Basic ${sessionStorage.getItem('token')}` } }
    )
  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
}

const checkAuth = () => {
  if(sessionStorage.getItem('token')) {
    return true
  }
  return false;
}


const getLoggedInUser = () => {
  if (sessionStorage.getItem('user')) {
    return sessionStorage.getItem('user')
  } else {
    return null
  }
  
}

export {logout, checkAuthWithApiCall, checkAuth, isUserAuthenticated, getLoggedInUser, login };