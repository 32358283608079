import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import {en, es, ca, fr, de, nl, pt, it } from './locales'
const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }, 
  ca: {
    translation: ca
  },
  fr: {
    translation: fr
  },
  de: {
    translation: de
  },
  nl: {
    translation: nl
  },
  pt: {
    translation: pt
  },
  it: {
    translation: it
  },
};

i18n
  .use(reactI18nextModule)
  .init({
    lng: sessionStorage.getItem('language') || 'en',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;